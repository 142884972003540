import { IonButton, IonIcon } from '@ionic/react';
import { add } from 'ionicons/icons';
import { setNewChatModal } from "../../store/modalSlice";
import React from 'react';
import { useAppDispatch } from '../../hooks/hook';

const ButtonForm: React.FC = () => {

    const dispatch = useAppDispatch();

    return (
        <IonButton color="medium" expand="block" shape='round' onClick={() => dispatch(setNewChatModal({newChat: true}))}>
            <IonIcon icon={add} />
        </IonButton>
    );
}

export default ButtonForm;