class MessageType {
    static getType(path: string): string|undefined
    {
       const ext = path.split('.').pop();

       switch (ext) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
                return 'image';

            case 'mp4':
            case 'webm':
            case 'ogg':
                return 'video';
            
            case 'mp3':
            case 'wav':
                return 'audio';
            
            case 'pdf':
            case 'doc':
            case 'docx':
            case 'xls':
            case 'xlsx':
            case 'ppt':
            case 'pptx':
            case 'zip':
                return 'document';
       }
    }
}

export default MessageType;