export interface UserCalls {
    id: string;
    name: string;
}

export interface Call {
    id: string;
    name: string;
    backgroundColor: string;
}

export class Users {

    static List: UserCalls[] = [
        {
            id: '00',
            name: 'Unobike',
        },
        {
            id: '01',
            name: 'Javi',
        },
        {
            id: '03',
            name: 'Alex',
        },
        {
            id: '07',
            name: 'David'
        },
        {
            id: '02',
            name: 'Gusi'
        },
        {
            id: '05',
            name: 'Tato'
        },
        {
            id: '09',
            name: 'Derio'
        },
        {
            id: '08',
            name: 'Octavio'
        },
        {
            id: '10',
            name: 'Louay'
        },
        {
            id: '13',
            name: 'Isi'
        },
        {
            id: '04',
            name: 'Alexis'
        },
    ];

    static getUser(userId: string|undefined): UserCalls | undefined
    {
      return Users.List.find(user => user.id === userId);
    }
}

export class Calls {

    static List: Call[] = [
        {
            id: 'Inbound',
            name: 'Entrante',
            backgroundColor: 'back-success',
        },
        {
            id: 'Outbound',
            name: 'Saliente',
            backgroundColor: 'back-primary',
        },
        {
            id: 'Missed',
            name: 'Perdida',
            backgroundColor: 'back-warning',
        },
        {
            id: 'Notanswered',
            name: 'No Respondida',
            backgroundColor: 'back-danger',
        },
    ];

    static getCall(callId: string|undefined): Call | undefined
    {
      return Calls.List.find(call => call.id === callId);
    }
}