import React from 'react';

import { Order, OrderItem } from '../../shared/domain/CustomerData';
import { IonAccordion, IonBadge, IonButton, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonRow, IonText } from '@ionic/react';
import { checkmarkCircleOutline, closeCircleOutline, openOutline } from 'ionicons/icons';
import { environment } from '../../environments/environment';

type Props = {
    order: Order,
};

const OrderData: React.FC<Props> = ({order}) => {

    const status = () => {
        switch (order.status) {
            case 1:
                return (<IonBadge color="danger" className='ion-margin-horizontal'>{order.status}</IonBadge>);
            case 2:
                return (<IonBadge color="" className='ion-margin-horizontal'>{order.status}</IonBadge>);
            case 3:
                return (<IonBadge color="warning" className='ion-margin-horizontal'>{order.status}</IonBadge>);
            case 4:
                return (<IonBadge color="success" className='ion-margin-horizontal'>{order.status}</IonBadge>);
            default:
                return (<IonBadge color="medium" className='ion-margin-horizontal'>{order.status}</IonBadge>);
        }
    }

    const paymentStatus = () => {
        if (order.paymentStatus) {
            return (<IonBadge color="success"><IonIcon icon={checkmarkCircleOutline}/></IonBadge>);
        } else {
            return (<IonBadge color="danger"><IonIcon icon={closeCircleOutline}/></IonBadge>);
        }
    }

    const dateCreatedOrder = () => {
        const date = new Date(order.dateCreated);
        return date.toLocaleDateString();
    }

    const expectedArrivalOrderItem = (OrderItem: OrderItem) => {
        const date = new Date(OrderItem.expectedArrival);
        return OrderItem.expectedArrival ? date.toLocaleDateString()+' - ' : '';
    }

    const itemStatus = (OrderItem: OrderItem) => {
        switch (OrderItem.productStatus) {
            case 1:
                return (<IonBadge color="warning" className='ion-margin-horizontal'>Pedido</IonBadge>);
            case 2:
                return (<IonBadge color="success" className='ion-margin-horizontal'>Stock</IonBadge>);
            case 3:
                return (<IonBadge color="primary" className='ion-margin-horizontal'>Enviado</IonBadge>);
            case 4:
                return (<IonBadge color="danger" className='ion-margin-horizontal'>Incidencia</IonBadge>);
            default:
                return '';
        }
    }
        
    return (
        <IonAccordion value={"order-"+order.id}>
            <IonItem slot="header" className='buttonAccordion'>
                <IonLabel>{order.number}</IonLabel>
                <IonText>
                    { dateCreatedOrder() }
                    { status() }
                    { paymentStatus() }
                </IonText>
            </IonItem>
            <div className="ion-padding" slot="content">
                <IonButton fill='clear' href={environment.order+order.id} target='_blank'><IonIcon icon={openOutline}/> Ver Pedido</IonButton>
                <IonGrid className="ion-no-padding">
                    <IonRow>
                        <IonCol size='6' sizeMd='4'>
                            { order.nameShip }
                        </IonCol>
                        <IonCol size='6' sizeMd='4'>
                            { order.email }
                        </IonCol>
                        <IonCol size='6' sizeMd='4'>
                            { order.phoneShip }
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size='12'>
                            { order.addressShip }
                        </IonCol>
                        <IonCol size='12'>
                            { order.cityShip } {order.postalShip} ({order.stateShip}) {order.countryShip}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size='12' className='ion-no-padding'>
                            <IonList>
                                <IonListHeader><h4>Artículos</h4></IonListHeader>
                                { order.orderItems.map((item) => {
                                    return (
                                        <IonItem lines='full' key={'order-item-'+item.id}>
                                            <IonLabel>{item.sku}<br/>{item.productName}<br/><i>{ item.productNote }</i></IonLabel>
                                            <IonText slot='end'>
                                                {expectedArrivalOrderItem(item)} Uds: {item.uds} {itemStatus(item)}
                                            </IonText>
                                        </IonItem>
                                    )
                                })}                        
                            </IonList>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size='12' sizeMd='6' className='ion-no-padding'>
                            <h4>Observaciones</h4>
                            { order.customerNote }
                        </IonCol>
                        <IonCol size='12' sizeMd='6' className='ion-no-padding'>
                            <h4>Notas Internas</h4>
                            { order.privateNote }
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div>
        </IonAccordion>
    );
}

export default OrderData;