import React, { useEffect, useState } from 'react';
import { Hydramember } from '../../shared/domain/Room';
import { IonAvatar, IonBadge, IonCol, IonContent, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonPopover, IonRow, IonText } from '@ionic/react';
import { personCircleOutline, logoWhatsapp, logoFacebook, logoInstagram, paperPlaneOutline, mailUnreadOutline, archiveOutline, arrowDownOutline, arrowUpOutline } from 'ionicons/icons';
import { useAppDispatch } from "../../hooks/hook";
import { setRoom } from "../../store/roomSlice";
import ApiRequest from '../../shared/ApiRequest';
import { useHistory } from "react-router-dom";
import { Users, User } from '../../application/enumUsers';

type Props = {
    data: Hydramember,
};

const RoomItem: React.FC<Props> = ({data}) => {

    const ItemContent = React.useRef<HTMLIonItemElement>(null);
    const history = useHistory();
    const dispatch = useAppDispatch();
    const [date, setDate] = useState<string>('');
    const [lastMessage, setLastMessage] = useState<string>('');
    const [affair, setAffair] = useState<string>('');
    const [messagesWithoutRead, setMessagesWithoutRead] = useState<number>(0);
    const [user, setUser] = React.useState<User|undefined>();

    useEffect(() => {
        setLastMessage(data.lastMessage);
        setAffair(data.affair);
        setMessagesWithoutRead(data.messagesWithoutRead);

        const oneDay = 24 * 60 * 60 * 1000;
        const now = new Date();
        const lastDate = new Date(data.lastMessageDate);
        const diff = now.getTime() - lastDate.getTime();
        
        if (diff > oneDay) {
            setDate(lastDate.toLocaleDateString('ES', { weekday: 'short' })+'.');
        } else {
            setDate(lastDate.toLocaleTimeString('ES', { hour: '2-digit', minute: '2-digit' }));
        }

        if (data.assisted) {
            const matches = data.assisted.match(/\d+/g);
            setUser(Users.getUser(Number(matches![0])));
        }
    }, [data]);

    const chatRoom = () => {
        dispatch(setRoom({roomId: data.id, affair: data.affair, avatar: data.image}));
        if (window.location.pathname === '/list') {
            history.push('/chat');
        }
    };

    ItemContent.current?.addEventListener('click', () => {
        chatRoom()
    });

    const changeStatus = (value: number) => {
        ApiRequest.patch("/es/rooms/"+data.id+"/status/"+value);
    };

    const changeIcon = () => {
        switch (data.systemType) {
            case 1:
                return <IonIcon size="small" color='success' icon={logoWhatsapp} />;
            case 2:
                return <IonIcon size="small" color="primary" icon={logoFacebook} />;
            case 3:
                return <IonIcon size="small" className='color-instagram' icon={logoInstagram} />;
            case 4:
                return <IonIcon size="small" color="primary" icon={paperPlaneOutline} />;
        }
    };

    const avatar = () => {
        if (data.image) {
            return <img src={data.image} alt={data.affair} />;
        } else {
            return <IonIcon size="large" icon={personCircleOutline} />;
        }
    }

    return (
        <IonItem className='room-stick ion-no-padding' button id={"clickRight-"+data.id} ref={ItemContent} lines="full">
            <IonAvatar>
                {avatar()}
            </IonAvatar>
            <IonLabel>
                <IonGrid>
                    <IonRow>
                        <IonCol size="10">
                            <IonText>
                                <h2>{changeIcon()} {affair}</h2>
                                <p className='text-secondary-room overflow-text'>{lastMessage}</p>
                            </IonText>
                        </IonCol>
                        <IonCol size="1">
                            <p className='text-secondary-room text-secondary-size-small'><i>{user?.name}</i></p>
                        </IonCol>
                        <IonCol size="1">
                            <IonText>
                                <p className='text-secondary-room text-secondary-size-small'>{date}</p>
                                { messagesWithoutRead > 0 ? <IonBadge color="unobike-red">{messagesWithoutRead}</IonBadge> : null }
                            </IonText>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonLabel>
            <IonPopover size="auto" side="bottom" alignment="end" trigger={"clickRight-"+data.id} triggerAction="context-menu">
                <IonContent>
                    <IonList>
                        <IonItem lines='full' button disabled={data.status === 1} onClick={() => changeStatus(1)} >
                            <IonIcon slot="start" icon={mailUnreadOutline} color='white' />
                            <IonLabel>Nuevo</IonLabel>
                        </IonItem>
                        <IonItem lines='full' button disabled={data.status === 3} onClick={() => changeStatus(3)} >
                            <IonIcon slot="start" icon={arrowUpOutline} color='white'/>
                            <IonLabel>Prioridad Alta</IonLabel>
                        </IonItem>
                        <IonItem lines='full' button disabled={data.status === 4} onClick={() => changeStatus(4)} >
                            <IonIcon slot="start" icon={arrowDownOutline} color='white'/>
                            <IonLabel>Prioridad Baja</IonLabel>
                        </IonItem>
                        <IonItem lines='full' button disabled={data.status === 2} onClick={() => changeStatus(2)} >
                            <IonIcon slot="start" icon={archiveOutline} color='white'/>
                            <IonLabel>Archivar</IonLabel>
                        </IonItem>
                    </IonList>
                </IonContent>
            </IonPopover>
        </IonItem>
    );
};

export default RoomItem;