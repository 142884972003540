export interface User {
    id: number;
    name: string;
}

export class Users {

    static List: User[] = [
        {
            id: 1,
            name: 'Paco',
        },
        {
            id: 2,
            name: 'Javi',
        },
        {
            id: 3,
            name: 'Alex',
        },
        {
            id: 4,
            name: 'David'
        },
        {
            id: 5,
            name: 'Gusi'
        },
        {
            id: 6,
            name: 'Bot'
        },
        {
            id: 7,
            name: 'Tato'
        },
        {
            id: 23,
            name: 'Derio'
        },
        {
            id: 3269,
            name: 'Octavio'
        },
        {
            id: 6431,
            name: 'Louay'
        },
        {
            id: 14258,
            name: 'Isi'
        },
        {
            id: 19235,
            name: 'Alexis'
        },
        {
            id: 19709,
            name: 'Jorge'
        },

    ];

    static getUser(userId: number|undefined): User | undefined
    {
      return Users.List.find(user => user.id === userId);
    }
}