import React from 'react';
import { Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, getPlatforms, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Main from './pages/Main';
import Login from './pages/Login';
import ListRooms from './components/Rooms/ListRooms';
import RoomChat from './components/Chat/RoomChat';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/unobike.css';

setupIonicReact();

import ApiRequest from './shared/ApiRequest';
import { Hydramember } from './shared/domain/Room';
import { setRoom } from './store/roomSlice';
import { useAppDispatch } from './hooks/hook';

const App: React.FC = () => {

  const dispatch = useAppDispatch();
  const isMobile = getPlatforms().includes('capacitor') || getPlatforms().includes('cordova') || getPlatforms().includes('mobile') || getPlatforms().includes('mobileweb');
  const queryParameters = new URLSearchParams(window.location.search);
  const phone = queryParameters.get('phone');

  if (window.location.pathname === '/index.html') {
    window.location.href = '/';
  }

  if (phone) {
    ApiRequest.get<Hydramember[]>("/es/rooms/search/"+phone).then(response => {
      const room = response.data[0];
      if (room) {
        dispatch(setRoom({roomId: room.id, affair: room.affair, avatar: room.image}));
        if (isMobile) {
          window.location.href = '/chat';
        }
      }
    });
  }

  return (
  <IonApp className='unobike-background'>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route path="/" component={Main}/>
        <Route path="/login" component={Login}/>
        <Route path="/list" component={ListRooms}/>
        <Route path="/chat" component={RoomChat}/>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
)};

export default App;
