import React, { useEffect } from 'react';
import { IonAccordion, IonAccordionGroup, IonAvatar, IonBadge, IonCol, IonContent, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonList, IonSearchbar, IonText, useIonLoading } from '@ionic/react';
import { Room, Hydramember } from '../../shared/domain/Room';
import ApiRequest from '../../shared/ApiRequest';
import RoomItem from './RoomItem';
import Mercure from '../../shared/Mercure';
import { personCircle } from 'ionicons/icons';
import AuthenticateJWT from '../../shared/Authenticate';
import { sortRoomLastDateDesc } from '../../application/sortRoomLastDate';
import ButtonForm from '../NewChat/ButtonForm';

const ListRooms: React.FC = () => {

    const itemsPerPage = 15;

    const [countNews, setCountNews] = React.useState<number>(0);
    const [countArchived, setCountArchived] = React.useState<number>(0);
    const [countHigh, setCountHigh] = React.useState<number>(0);
    const [countLow, setCountLow] = React.useState<number>(0);

    const [searchText, setSearchText] = React.useState<string|null|undefined>(null);
    const [listSearch, setListSearch] = React.useState<Hydramember[]>([]);
    const [listNews, setListNews] = React.useState<Hydramember[]>([]);
    const [listHigh, setListHigh] = React.useState<Hydramember[]>([]);
    const [listLow, setListLow] = React.useState<Hydramember[]>([]);
    const [listArchived, setListArchived] = React.useState<Hydramember[]>([]);

    const [updateNews, setUpdateNews] = React.useState<boolean>(true);
    const [updateArchived, setUpdateArchived] = React.useState<boolean>(true);
    const [updateHigh, setUpdateHigh] = React.useState<boolean>(true);
    const [updateLow, setUpdateLow] = React.useState<boolean>(true);

    const [room, setRoom] = React.useState<Hydramember>();
    const user = AuthenticateJWT.getUser();
    const [present, dismiss] = useIonLoading();

    useEffect(() => {
        if (updateNews) {
            ApiRequest.get<Room>("/es/rooms?status=1&itemsPerPage="+itemsPerPage+"&page=1&order[lastMessageDate]=desc").then(response => {
                setListNews( response.data["hydra:member"] );
            });
            setUpdateNews(false);
        }
    }, [updateNews]);

    useEffect(() => {
        if (updateArchived) {
            ApiRequest.get<Room>("/es/rooms?status=2&itemsPerPage="+itemsPerPage+"&page=1&order[lastMessageDate]=desc").then(response => {
                setListArchived( response.data["hydra:member"] );
            });
            setUpdateArchived(false);
        }
    }, [updateArchived]);

    useEffect(() => {
        if (updateHigh) {
            ApiRequest.get<Room>("/es/rooms?status=3&itemsPerPage="+itemsPerPage+"&page=1&order[lastMessageDate]=desc").then(response => {
                setListHigh( response.data["hydra:member"] );
            });
            setUpdateHigh(false);
        }
    }, [updateHigh]);

    useEffect(() => {
        if (updateLow) {
            ApiRequest.get<Room>("/es/rooms?status=4&itemsPerPage="+itemsPerPage+"&page=1&order[lastMessageDate]=desc").then(response => {
                setListLow( response.data["hydra:member"] );
            });
            setUpdateLow(false);
        }
    }, [updateLow]);

    useEffect(() => {
        setCountNews(0);
        listNews.map((item) => {
            if (item.messagesWithoutRead) {
                setCountNews((value) => ++value);
            }
        });
    }, [listNews]);

    useEffect(() => {
        setCountArchived(0);
        listArchived.map((item) => {
            if (item.messagesWithoutRead) {
                setCountArchived((value) => ++value);
            }
        });
    }, [listArchived]);

    useEffect(() => {
        setCountHigh(0);
        listHigh.map((item) => {
            if (item.messagesWithoutRead) {
                setCountHigh((value) => ++value);
            }
        });
    }, [listHigh]);

    useEffect(() => {
        setCountLow(0);
        listLow.map((item) => {
            if (item.messagesWithoutRead) {
                setCountLow((value) => ++value);
            }
        });
    }, [listLow]);

    useEffect(() => {
        Mercure.subscribe("rooms", (room: Hydramember) => {
            setRoom(room);
        });
    }, []);

    useEffect(() => {
        if (room) {
            let newList = [] as Hydramember[];

            switch (room.status) {
                case 1:
                    newList = [...listNews];
                break;

                case 2:
                    newList = [...listArchived];
                break;

                case 3:
                    newList = [...listHigh];
                break;

                case 4:
                    newList = [...listLow];
                break;
            }

            const elementListNews = listNews.findIndex((item) => item.id === room.id);
            const elementListArchived = listArchived.findIndex((item) => item.id === room.id);
            const elementListHigh = listHigh.findIndex((item) => item.id === room.id);
            const elementListLow = listLow.findIndex((item) => item.id === room.id);

            if (elementListNews !== -1) {
                listNews.splice(elementListNews, 1);
                setUpdateNews(true);
            }

            if (elementListArchived !== -1) {
                listArchived.splice(elementListArchived, 1);
                setUpdateArchived(true);
            }

            if (elementListHigh !== -1) {
                listHigh.splice(elementListHigh, 1);
                setUpdateHigh(true);
            }

            if (elementListLow !== -1) {
                listLow.splice(elementListLow, 1);
                setUpdateLow(true);
            }

            const elementList = newList.findIndex((item) => item.id === room.id);
            if (elementList === -1) {
                newList.push(room);
            } else {
                newList[elementList] = room;
            }

            newList.sort(sortRoomLastDateDesc);

            switch (room.status) {
                case 1:
                    setListNews(newList);
                break;

                case 2:
                    setListArchived(newList);
                break;

                case 3:
                    setListHigh(newList);
                break;

                case 4:
                    setListLow(newList);
                break;
            }
        }
    }, [room]);

    const generateItemsNews = () => {
        const page = Math.ceil(listNews.length / itemsPerPage) + 1;
        ApiRequest.get<Room>("/es/rooms?status=1&itemsPerPage="+itemsPerPage+"&page="+page+"&order[lastMessageDate]=desc").then(response => {
            setListNews([...listNews, ...response.data["hydra:member"]]);
        });
    }

    const generateItemsArchived = () => {
        const page = Math.ceil(listArchived.length / itemsPerPage) + 1;
        ApiRequest.get<Room>("/es/rooms?status=2&itemsPerPage="+itemsPerPage+"&page="+page+"&order[lastMessageDate]=desc").then(response => {
            setListArchived([...listArchived, ...response.data["hydra:member"]]);
        });
    }

    const generateItemsHigh = () => {
        const page = Math.ceil(listHigh.length / itemsPerPage) + 1;
        ApiRequest.get<Room>("/es/rooms?status=3&itemsPerPage="+itemsPerPage+"&page="+page+"&order[lastMessageDate]=desc").then(response => {
            setListHigh([...listHigh, ...response.data["hydra:member"]]);
        });
    }

    const generateItemsLow = () => {
        const page = Math.ceil(listLow.length / itemsPerPage) + 1;
        ApiRequest.get<Room>("/es/rooms?status=4&itemsPerPage="+itemsPerPage+"&page="+page+"&order[lastMessageDate]=desc").then(response => {
            setListLow([...listLow, ...response.data["hydra:member"]]);
        });
    }

    const searchRoom = (ev: Event) => {
        present({
            message: 'Buscando...',
            spinner: 'circular',
        });

        const target = ev.target as HTMLIonSearchbarElement;
        setSearchText(target.value);
        if (target.value) {
            ApiRequest.get<Hydramember[]>("/es/rooms/search/"+target.value).then(response => {
                setListSearch(response.data);
                dismiss();
            });
        } else {
            setListSearch([]);
            setTimeout(() => {
                dismiss();
            },100);
        }
    }

    const showContent = () => {
        if (listSearch.length > 0) {
            return <IonList lines="full">
                        {listSearch.map((item) => {
                            return (
                                <RoomItem key={'room-'+item.id} data={item} />
                            );
                        })}
                    </IonList>
        } else if (searchText && listSearch.length === 0) {
            return <IonText><p className='ion-text-center'>No se ha encontrado ninguna conversación</p></IonText>
        } else {
            return <IonAccordionGroup>
            <IonAccordion value="news">
                <IonItem slot="header" color="light" className='buttonAccordion'>
                    <IonLabel>Nuevos</IonLabel>
                    { (countNews === 0) ? null : <IonBadge color="unobike-red" slot="end" >{countNews}</IonBadge> }
                </IonItem>
                <div className="ion-padding" slot="content">
                    <IonList lines="full">
                        {listNews.map((item) => {
                            return (
                                <RoomItem key={'room-'+item.id} data={item} />
                            );
                        })}
                    </IonList>
                    <IonInfiniteScroll threshold='10%' onIonInfinite={(ev) => { generateItemsNews(); setTimeout(() => ev.target.complete(), 100); }}>
                        <IonInfiniteScrollContent></IonInfiniteScrollContent>
                    </IonInfiniteScroll>
                </div>
            </IonAccordion>
            <IonAccordion value="high">
                <IonItem slot="header" color="light" className='buttonAccordion'>
                    <IonLabel>Prioridad Alta</IonLabel>
                    { (countHigh === 0) ? null : <IonBadge color="unobike-red" slot="end" >{countHigh}</IonBadge> }
                </IonItem>
                <div className="ion-padding" slot="content">
                    <IonList lines="full">
                        {listHigh.map((item) => {
                            return (
                                <RoomItem key={'room-'+item.id} data={item} />
                            );
                        })}
                    </IonList>
                    <IonInfiniteScroll threshold='10%' onIonInfinite={(ev) => { generateItemsHigh(); setTimeout(() => ev.target.complete(), 100); }}>
                        <IonInfiniteScrollContent></IonInfiniteScrollContent>
                    </IonInfiniteScroll>
                </div>
            </IonAccordion>
            <IonAccordion value="low">
                <IonItem slot="header" color="light" className='buttonAccordion'>
                    <IonLabel>Prioridad Baja</IonLabel>
                    { (countLow === 0) ? null : <IonBadge color="unobike-red" slot="end" >{countLow}</IonBadge> }
                </IonItem>
                <div className="ion-padding" slot="content">
                    <IonList lines="full">
                        {listLow.map((item) => {
                            return (
                                <RoomItem key={'room-'+item.id} data={item} />
                            );
                        })}
                    </IonList>
                    <IonInfiniteScroll threshold='10%' onIonInfinite={(ev) => { generateItemsLow(); setTimeout(() => ev.target.complete(), 100); }}>
                        <IonInfiniteScrollContent></IonInfiniteScrollContent>
                    </IonInfiniteScroll>
                </div>
            </IonAccordion>
            <IonAccordion value="archived">
                <IonItem slot="header" color="light" className='buttonAccordion'>
                    <IonLabel>Archivados</IonLabel>
                    { (countArchived === 0) ? null : <IonBadge color="unobike-red" slot="end" >{countArchived}</IonBadge> }
                </IonItem>
                <div className="ion-padding" slot="content">
                    <IonList lines="full">
                        {listArchived.map((item) => {
                            return (
                                <RoomItem key={'room-'+item.id} data={item} />
                            );
                        })}
                    </IonList>
                    <IonInfiniteScroll threshold='10%' onIonInfinite={(ev) => { generateItemsArchived(); setTimeout(() => ev.target.complete(), 100); }}>
                        <IonInfiniteScrollContent></IonInfiniteScrollContent>
                    </IonInfiniteScroll>
                </div>
            </IonAccordion>
        </IonAccordionGroup>
        }
    }

    return (
        <IonCol sizeMd='3' size='12' className="ion-no-padding border-col-right unobike-background">
            <IonItem lines="full" className='shadow-box-bottom'>
                <IonAvatar slot='start'>
                    <IonIcon icon={personCircle} size='large' />
                </IonAvatar>
                <IonLabel>
                    <h2>{user?.name}</h2>
                </IonLabel>
                <ButtonForm />
            </IonItem>
            <IonSearchbar color='light' placeholder="Buscar conversación..." debounce={500} onIonInput={(ev) => searchRoom(ev)}></IonSearchbar>
            <IonContent className='full-height'>
                { showContent() }
            </IonContent>
        </IonCol>
    );
}

export default ListRooms;