import { configureStore } from '@reduxjs/toolkit';
import toastReducer from './toastSlice'
import roomSlice from './roomSlice';
import modalSlice from './modalSlice';

export const store = configureStore({
  reducer: {
    toast: toastReducer,
    room: roomSlice,
    modal: modalSlice,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch