import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './Store';

interface modalState {
    newChat: boolean,
}

const initialState: modalState = {
    newChat: false,
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setNewChatModal: (state, action: PayloadAction<{newChat: boolean}>) => {
      state.newChat = action.payload.newChat
    },
  }
});

export const { setNewChatModal } = modalSlice.actions
export const modalReducer = (state: RootState) => state.modal
export default modalSlice.reducer