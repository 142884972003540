import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './Store';

interface RoomState {
  id: number,
  affair: string,
  avatar: string|undefined,
}

const initialState: RoomState = {
    id: 0,
    affair: '',
    avatar: '',
}

export const roomSlice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    setRoom: (state, action: PayloadAction<{roomId: number, affair: string, avatar: string|undefined}>) => {
      state.id = action.payload.roomId
      state.affair = action.payload.affair
      state.avatar = action.payload.avatar
    },
  }
});

export const { setRoom } = roomSlice.actions
export const roomReducer = (state: RootState) => state.room
export default roomSlice.reducer