import React, { useEffect } from 'react';
import { IonContent, IonGrid, IonPage, IonRow, getPlatforms } from '@ionic/react';
import '../theme/unobike.css';
import ListRooms from '../components/Rooms/ListRooms';
import RoomChat from '../components/Chat/RoomChat';

const Main: React.FC = () => {

  const isMobile = getPlatforms().includes('capacitor') || getPlatforms().includes('cordova') || getPlatforms().includes('mobile') || getPlatforms().includes('mobileweb');

  useEffect(() => {
    if (isMobile) {
      window.location.href = "/list";
    }
  }, []);

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonGrid no-padding>
          <IonRow>
            < ListRooms />
            < RoomChat />
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Main;
