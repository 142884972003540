import { Message } from '../shared/domain/Message';

export function sortMessageDateDesc (dataA: Message, dataB: Message): number
{
    const dateA = new Date(dataA.createAt);
    const dateB = new Date(dataB.createAt);
    return dateB.getTime() - dateA.getTime();
}

export function sortMessageDateAsc (dataA: Message, dataB: Message): number
{
    const dateA = new Date(dataA.createAt);
    const dateB = new Date(dataB.createAt);
    return  dateA.getTime() - dateB.getTime();
}