import React, { useEffect } from 'react';
import { Data, Order, Call, ChatUser } from '../../shared/domain/CustomerData';
import { IonModal, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, IonGrid, IonCol, IonRow, IonItem, IonAccordionGroup, IonList, useIonLoading } from '@ionic/react';
import ApiRequest from '../../shared/ApiRequest';
import OrderData from './OrderData';
import CallData from './CallData';

type Props = {
    roomId: number,
    openModal: boolean,
    setOpenModal: (openModal: boolean) => void,
};

const Customer: React.FC<Props> = ({roomId, openModal, setOpenModal}) => {

    const [orders, setOrders] = React.useState<Order[]>([]);
    const [calls, setCalls] = React.useState<Call[]>([]);
    const [chatUser, setChatUser] = React.useState<ChatUser>();
    const [present, dismiss] = useIonLoading();

    useEffect(() => {
        if (openModal && roomId) {
            present({
                message: 'Comprobando...',
                spinner: 'circular',
            });

            ApiRequest.get<Data>("/es/rooms/"+roomId+"/customer").then(response => {
                setOrders(response.data.orders);
                setCalls(response.data.calls);
                setChatUser(response.data.chatUser);
            }).then(() => {
                dismiss();
            });
        }
    }, [openModal]);    
        
    return (
        <IonModal className="modal-customer" isOpen={openModal} onDidDismiss={()=>setOpenModal(false)}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Datos del Cliente</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setOpenModal(false)}>Cerrar</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen>
            <IonGrid>
                <IonRow>
                    <IonCol size='12'>
                        <IonItem><h3>Usuario</h3></IonItem>
                        <IonGrid>
                            <IonRow>
                                <IonCol sizeMd='4' size='6'>
                                    <p>Nombre: {chatUser?.name}</p>
                                </IonCol>
                                <IonCol sizeMd='4' size='6'>
                                    <p>Email: {chatUser?.email}</p>
                                </IonCol>
                                <IonCol sizeMd='4' size='6'>
                                    <p>Telefono: {chatUser?.phone}</p>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol sizeMd='8' size='12'>
                        <IonItem><h3>Pedidos</h3></IonItem>
                        <IonAccordionGroup>
                            { orders.map((order) => { return (<OrderData key={'order-'+order.id} order={order} />)}) }
                        </IonAccordionGroup>
                    </IonCol>
                    <IonCol sizeMd='4' size='12'>
                        <IonItem><h3>Llamadas</h3></IonItem>
                        <IonList className='ion-no-padding'>
                            { calls.map((call) => { return (<CallData key={'call-'+call.id} call={call} />)}) }
                        </IonList>
                    </IonCol>
                </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>
    );
}

export default Customer;