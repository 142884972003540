import axios from 'axios';
import { environment } from '../environments/environment';
import AuthenticateJWT from './Authenticate';

const ApiRequest = axios.create({
    baseURL: environment.api,
});

ApiRequest.interceptors.request.use(
  (config: any) => {
    if (config.url !== '/login_check') {
      if (!AuthenticateJWT.isAuthenticated() && !AuthenticateJWT.isEmployee()) {
        window.location.href="/login";
        return null;
      }
    }

    config.headers.Authorization = `Bearer ${AuthenticateJWT.getToken()}`;

    return config;
}, function (error) {
  return Promise.reject(error);
});

ApiRequest.interceptors.response.use(
  response => {     
    return response;
  },
  error => {
    return Promise.reject(error);
});


export default ApiRequest;
