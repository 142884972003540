import React, { useRef } from 'react';
import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonInput, IonItem, IonModal, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar, getPlatforms } from '@ionic/react';
import { modalReducer,setNewChatModal } from "../../store/modalSlice";
import { useAppDispatch, useAppSelector } from '../../hooks/hook';
import ApiRequest from '../../shared/ApiRequest';
import { showToast } from '../../store/toastSlice';
import { setRoom } from '../../store/roomSlice';
import { NewChat } from '../../shared/domain/NewChat';


const ModalForm: React.FC = () => {

    const selectSystem = useRef<HTMLIonSelectElement>(null);
    const inputPhone = useRef<HTMLIonInputElement>(null);
    const isMobile = getPlatforms().includes('capacitor') || getPlatforms().includes('cordova') || getPlatforms().includes('mobile') || getPlatforms().includes('mobileweb');
    const modal = useAppSelector(modalReducer);
    const dispatch = useAppDispatch();

    const addNewChat = () => {
        const system = selectSystem.current?.value;
        const phone = inputPhone.current?.value;

        if(system && phone) {
            ApiRequest.post<NewChat>("/es/rooms/add",{system: system, phone: phone}).then(response => {
                dispatch(setRoom({roomId: response.data.id, affair: response.data.affair, avatar: response.data.image}));
                dispatch(setNewChatModal({newChat: false}));

                if (isMobile) {
                    window.location.href = "/chat";
                }
            }).catch(error => {
                dispatch(showToast({type: "danger", message: error.response.status + " " + error.response.statusText + ": " + error.response.data.message})); 
            });
        } else {
            dispatch(showToast({type: "danger", message: 'Debes rellenar todos los campos correctamente'})); 
        }
    }

    return (
        <IonModal className="modal-newChat" isOpen={modal.newChat} onDidDismiss={() => dispatch(setNewChatModal({newChat: false}))}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Nueva Conversación</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => dispatch(setNewChatModal({newChat: false}))}>Cerrar</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid className='ion-no-padding'>
                    <IonRow>
                        <IonCol>
                            <IonItem>
                                <IonSelect ref={selectSystem} color="unobike-red" label="Sistema" labelPlacement="floating" interface="popover">
                                    <IonSelectOption value="1">Whatsapp</IonSelectOption>
                                </IonSelect>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem>
                                <IonInput ref={inputPhone} color="unobike-red" label="Número Tlf." labelPlacement="floating" placeholder="Número de teléfono con prefijo" />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-text-center ion-margin-top">
                            <IonButton color="unobike-red" onClick={() => addNewChat()}>Añadir</IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonModal>
    );
}

export default ModalForm;