import React from 'react';
import { Call } from '../../shared/domain/CustomerData';
import { IonCol, IonGrid, IonItem, IonRow } from '@ionic/react';
import { Users, Calls } from '../../application/enumCalls';

type Props = {
    call: Call,
};

const CallData: React.FC<Props> = ({call}) => {

    const callType = Calls.getCall(call.callType);
    const user = Users.getUser(call.agent);
    const date = new Date(call.date);

    const dateString = date.toLocaleTimeString()+' '+date.toLocaleDateString();
            
    return (
        <IonItem lines='full'>
            <IonGrid className='ion-no-padding'>
                <IonRow>
                    <IonCol size='3' className={callType?.backgroundColor}>
                        <p className='ion-text-center'>{callType?.name}</p>
                    </IonCol>
                    <IonCol size='3'>
                        <p className='ion-text-center'>{user?.name}</p>
                    </IonCol>
                    <IonCol size='3'>
                        <p className='ion-text-center'>{call.duration}</p>
                    </IonCol>
                    <IonCol size='3'>
                        <p className='ion-text-center call-time'>{dateString}</p>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonItem>
    );
}

export default CallData;