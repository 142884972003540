import React, { useEffect } from 'react';
import { Message } from '../../shared/domain/Message';
import { IonItem, IonText, IonThumbnail } from '@ionic/react';
import { Users, User } from '../../application/enumUsers';
import { environment } from '../../environments/environment';
import MessageType from './MessageType';
import IonPhotoViewer from '@codesyntax/ionic-react-photo-viewer';

type Props = {
    data: Message,
};

const MessageItem: React.FC<Props> = ({data}) => {

    const positionMessage = data.user ? 'end' : 'start';
    const colorMessage = data.user ? 'text-bubble-user' : 'text-bubble-customer';
    const [date, setDate] = React.useState<string>('');
    const [user, setUser] = React.useState<User|undefined>();

    useEffect(() => {
        const oneDay = 24 * 60 * 60 * 1000;
        const now = new Date();
        const lastDate = new Date(data.createAt);
        const diff = now.getTime() - lastDate.getTime();
        
        if (diff > oneDay) {
            setDate(lastDate.toLocaleDateString('ES', { weekday: 'short' })+'.');
        } else {
            setDate(lastDate.toLocaleTimeString('ES', { hour: '2-digit', minute: '2-digit' }));
        }

        if (data.user) {
            const matches = data.user.match(/\d+/g);
            setUser(Users.getUser(Number(matches![0])));
        }
    }, [data]);

    const getContent = () => {
        if (data.message) {
            return <span className='white-space-message'>{data.message}</span>;
        } else {
            const type = MessageType.getType(data.file);
            switch (type) {
                case 'image':
                    return  <IonThumbnail className='message-thumb'>
                                <IonPhotoViewer title="image" src={environment.api+data.file}>
                                    <img src={environment.api+data.file} className='message-thumb-max'/>
                                </IonPhotoViewer>
                            </IonThumbnail>
                case 'video':
                    return <video className='message-thumb-video' src={environment.api+data.file} controls />
                case 'audio':
                    return <audio src={environment.api+data.file} controls />
                case 'document':
                    return <a href={environment.api+data.file} rel="noreferrer" target='_blank'>Archivo</a>
                default:
                    return <span className='white-space-message'>*.*Archivo no soportado*.*</span>;
            }
        }
    }

    return (
        <IonItem lines='none' className='message-item'>
            <IonText slot={positionMessage} className={colorMessage}>
                {getContent()}<br/>
                <i>{user?.name} {date}</i>
            </IonText>
        </IonItem>
    );
}

export default MessageItem;