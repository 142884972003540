import { IonList, IonCol, IonAvatar, IonIcon, IonItem, IonLabel, IonTextarea, IonContent, IonButton, useIonLoading, IonBackButton, IonInfiniteScroll, IonInfiniteScrollContent, IonToolbar, IonGrid, IonRow, IonPopover, IonActionSheet, getPlatforms } from '@ionic/react';
import { paperPlaneOutline, personCircle, attachOutline, menuOutline, happyOutline, listOutline, callOutline } from 'ionicons/icons';
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { roomReducer } from "../../store/roomSlice";
import React, { useEffect, useRef, useState } from 'react';
import ApiRequest from '../../shared/ApiRequest';
import { Message } from '../../shared/domain/Message';
import MessageItem from './MessageItem';
import Mercure from '../../shared/Mercure';
import Customer from '../Customer/Customer';
import { sortMessageDateAsc } from '../../application/sortMessageDate';
import ButtonForm from '../NewChat/ButtonForm';
import Unique from '../../shared/Unique';
import Picker, { Theme, SuggestionMode, EmojiClickData, EmojiStyle } from 'emoji-picker-react';
import { showToast } from '../../store/toastSlice';
import AuthenticateJWT from '../../shared/Authenticate';
import { ChatUser } from '../../shared/domain/CustomerData';

const RoomChat: React.FC = () => {

  const itemsPerPage = 20;
  const content = useRef<HTMLIonContentElement>(null);
  const fileInput = useRef<HTMLInputElement>(null);
  const room = useAppSelector(roomReducer);
  const [message, setMessage] = useState<Message>();
  const [messages, setMessages] = useState<Message[]>([]);
  const [present, dismiss] = useIonLoading();
  const [scrollBottom, setScrollBottom] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const textarea = useRef<HTMLIonTextareaElement>(null);
  const [rapidAnswer, setRapidAnswer] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const isMobile = getPlatforms().includes('capacitor') || getPlatforms().includes('cordova') || getPlatforms().includes('mobile') || getPlatforms().includes('mobileweb');
  const [chatUser, setChatUser] = useState<ChatUser>();

  useEffect(() => {
    if (room.id) {
      ApiRequest.get<Message[]>("/es/rooms/" + room.id + "/messages?itemsPerPage=" + itemsPerPage + "&page=1&order=desc").then(response => {
        response.data.sort(sortMessageDateAsc);
        setMessages(response.data);
        setScrollBottom(true);
      });

      ApiRequest.get("/es/rooms/" + room.id + "/resetCount");

      ApiRequest.get<ChatUser>("/es/rooms/" + room.id + "/chatuser").then(response => {
        setChatUser(response.data);
      });
    }
  }, [room]);

  useEffect(() => {
    if (scrollBottom) {
      setTimeout(() => {
        content.current?.scrollToBottom(0);
        setScrollBottom(false);
      }, 150);
    }
  }, [scrollBottom]);

  useEffect(() => {
    Mercure.subscribe("roomMessages", (message: Message) => {
      setMessage(message);
    });
  }, []);

  useEffect(() => {
    if (message) {
      const matches = message.room.match(/\d+/g);
      if (room.id === Number(matches![0])) {
        const newList = [...messages];
        let itsCustomer = false;

        const elementList = messages.findIndex((item) => item.id === message.id || item.identifier === message.identifier);
        if (elementList === -1) {
          newList.push(message);
          if (!message.user) {
            itsCustomer = true;
          }
        } else {
          newList[elementList] = message;
        }

        newList.sort(sortMessageDateAsc);
        setMessages(newList);
        ApiRequest.get("/es/rooms/" + room.id + "/resetCount");

        if (itsCustomer) {
          setScrollBottom(true);
        }
      }
    }
  }, [message]);

  const sendMessage = () => {
    if (room.id) {
      const messageSend = textarea.current?.value;
      const unique = Unique.generate(64);

      if (messageSend) {
        ApiRequest.post<Message>("/es/rooms/" + room.id + "/send/text", {
          message: messageSend,
          identifier: unique
        });

        const newList = [...messages];
        newList.push({
          id: 0,
          identifier: unique,
          room: '/es/rooms/' + room.id,
          message: messageSend,
          user: '/es/users/' + AuthenticateJWT.getUser()!.sub,
          type: 1,
          status: 1,
          createAt: new Date().toISOString()
        });
        setMessages(newList);

        textarea.current.value = "";
        setScrollBottom(true);
      }
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLIonTextareaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }

    if (event.key == '#' || textarea.current!.value?.includes('#')) {
      setRapidAnswer(true);
    }
  }

  const uploadFile = () => {
    if (room.id) {
      present({
        message: 'Subiendo...',
        spinner: 'circular',
      });

      const file = fileInput!.current!.files![0];
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("identifier", Unique.generate(64));

        ApiRequest.post<Message>("/es/rooms/" + room.id + "/send/file", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(() => {
          dismiss();
          fileInput!.current!.value = "";
          setScrollBottom(true);
        });
      }
    }
  }

  const buttonReturn = () => {
    if (window.location.pathname === '/chat') {
      return <IonBackButton defaultHref="/list" />
    }
  }

  const generateItems = () => {
    const newList = [...messages];

    const page = Math.ceil(messages.length / itemsPerPage) + 1;
    ApiRequest.get<Message[]>("/es/rooms/" + room.id + "/messages?itemsPerPage=" + itemsPerPage + "&page=" + page + "&order=desc").then(response => {
      newList.push(...response.data);
      newList.sort(sortMessageDateAsc);

      setMessages(newList);
    });
  }

  const contentMessage = () => {
    if (room.id) {
      return <><IonInfiniteScroll position="top" threshold='25%' onIonInfinite={(ev) => { generateItems(); setTimeout(() => ev.target.complete(), 200); }}>
        <IonInfiniteScrollContent></IonInfiniteScrollContent>
      </IonInfiniteScroll>
        <IonList className='chatbox-padding-bottom'>
          {messages.map((item) => {
            return (
              <MessageItem key={'message-' + item.id} data={item} />
            );
          })}
        </IonList></>
    } else {
      return <IonGrid>
        <IonRow className='ion-justify-content-center ion-align-items-center'>
          <IonCol size='auto'>
            <IonItem lines='none'>
              <IonLabel>Nueva conversación</IonLabel>
              <ButtonForm />
            </IonItem>
          </IonCol>
        </IonRow>
      </IonGrid>
    }
  }

  const avatar = () => {
    if (room.avatar) {
      return <img src={room.avatar} alt={room.affair} />;
    } else if (room.id) {
      return <IonIcon size="large" icon={personCircle} />;
    } else {
      return ''
    }
  }

  function onClickEmoji(emojiData: EmojiClickData) {
    textarea.current!.value += emojiData.emoji;
  }

  function newConversation() {
    ApiRequest.get("/es/rooms/" + room.id + "/newConversation")
      .then()
      .catch(error => {
        dispatch(showToast({ type: "danger", message: error.response.data.message }));
      });

    setRapidAnswer(false);
    textarea.current!.value = '';
  }

  function shipment() {
    ApiRequest.get("/es/rooms/" + room.id + "/shipment")
      .then()
      .catch(error => {
        dispatch(showToast({ type: "danger", message: error.response.data.message }));
      });

    setRapidAnswer(false);
    textarea.current!.value = '';
  }

  function payment() {
    ApiRequest.get("/es/rooms/" + room.id + "/payment")
      .then()
      .catch(error => {
        dispatch(showToast({ type: "danger", message: error.response.data.message }));
      });

    setRapidAnswer(false);
    textarea.current!.value = '';
  }

  function buttonRapideAnswer() {
    setRapidAnswer(true);
  }

  const buttonEmoji = () => {
    if (isMobile) {
      return <IonButton slot='start' fill="clear" color='medium' className='ion-no-padding' onClick={buttonRapideAnswer}>
        <IonIcon icon={listOutline} size='large' slot="icon-only" />
      </IonButton>
    } else {
      return <IonButton slot='start' fill="clear" color='medium' id="emojis" className='ion-no-padding'>
        <IonIcon icon={happyOutline} size='large' slot="icon-only" />
      </IonButton>
    }
  }

  const buttonCall = () => {
    if (chatUser?.phone) {
      return <IonButton slot='end' size='small' shape='round' color="medium" target="_blank" href={'https://178.33.118.117:5001/webclient/#/people?phone=' + chatUser?.phone}>
        <IonIcon icon={callOutline} slot="icon-only" />
      </IonButton>
    }
    return <></>
  }

  return (
    <IonCol sizeMd='9' size='12' className='ion-no-padding unobike-background'>
      <IonItem lines="full" className='shadow-box-bottom'>
        {buttonReturn()}
        <IonAvatar>
          {avatar()}
        </IonAvatar>
        <IonLabel className='ion-margin-start'>
          {room.affair}
        </IonLabel>
        {buttonCall()}
        <IonButton slot='end' size='small' expand="block" color="medium" onClick={() => setOpenModal(true)}>
          <IonIcon icon={menuOutline} size="large" slot="icon-only" />
        </IonButton>
        <Customer roomId={room.id} openModal={openModal} setOpenModal={setOpenModal} />
      </IonItem>
      <IonContent ref={content}>
        {contentMessage()}
      </IonContent>
      <IonPopover trigger='emojis' side='top' keepContentsMounted={false} className='poppoverEmojis'>
        <Picker
          autoFocusSearch={false}
          searchDisabled={true}
          theme={Theme.DARK}
          suggestedEmojisMode={SuggestionMode.FREQUENT}
          previewConfig={{
            showPreview: false,
          }}
          onEmojiClick={onClickEmoji}
          emojiStyle={EmojiStyle.NATIVE}
        />
      </IonPopover>
      <IonToolbar className='chat-padding-top shadow-box-top fix-bottom'>
        <IonButton slot='start' expand="block" size="small" shape="round" color="unobike-red" onClick={() => fileInput!.current!.click()}>
          <IonIcon icon={attachOutline} slot="icon-only" />
        </IonButton>
        {buttonEmoji()}
        <input hidden ref={fileInput} type="file" onChange={() => uploadFile()} />
        <IonTextarea
          className='chat-textarea'
          labelPlacement="fixed"
          color="unobike-red"
          fill="outline"
          slot='start'
          onKeyPress={handleKeyPress}
          rows={2}
          ref={textarea}
        ></IonTextarea>
        <IonButton slot='end' expand="block" shape="round" size="small" color="unobike-red" onClick={() => sendMessage()}>
          <IonIcon icon={paperPlaneOutline} slot="icon-only" />
        </IonButton>
      </IonToolbar>
      <IonActionSheet
        isOpen={rapidAnswer}
        header="Acciones rápidas"
        onDidDismiss={() => setRapidAnswer(false)}
        className='text-button-sheet'
        buttons={[
          {
            text: '#Nueva conversación',
            handler: () => newConversation()
          },
          {
            text: '#Seguimiento',
            handler: () => shipment()
          },
          {
            text: '#Pago',
            handler: () => payment()
          },
          {
            text: 'Cerrar',
            role: 'cancel',
            data: {
              action: 'cancel',
            },
          }
        ]}
      />
    </IonCol>
  );
}

export default RoomChat;
