import { EventSourcePolyfill } from 'event-source-polyfill';
import { environment } from '../environments/environment';

class Mercure {
    static subscribe(topic: string, callback: (data: any) => void) {
        const url = new URL(environment.mercure);
        url.searchParams.append('topic', topic);

        const eventSource = new EventSourcePolyfill(url.toString(), {
            headers: {
                'Authorization': 'Bearer '+environment.mercureToken,
            },
            heartbeatTimeout: 60000,
        });
        
        eventSource.onmessage = (e: any) => {
            callback(JSON.parse(e.data));
        };
    }
}

export default Mercure;