import { User, UserRole } from './domain/User';
import { getCookie } from 'typescript-cookie';

class AuthenticateJWT {
    
    static getToken(): string
    {
        if (getCookie("Bearer")) {
            return getCookie("Bearer") as string;
        } else {
            return localStorage.getItem("Bearer") as string;
        }
    }
    
    static saveToken(token: string) {
        localStorage.setItem("Bearer", token);
    }
    
    static removeToken() {
        localStorage.removeItem("Bearer");
    }

    static getUser(): User | null {
        const token = AuthenticateJWT.getToken();
        if (token) {
            return JSON.parse(atob(token.split(".")[1])) as User;
        }
        return null;
    }

    static isAdmin(): boolean {
        const user = AuthenticateJWT.getUser();
        if (!user) {
            return false;
        }

        return user.roles.includes(UserRole.ROLE_ADMIN);
    }

    static isEmployee(): boolean {
        const user = AuthenticateJWT.getUser();
        if (!user) {
            return false;
        }

        return user.roles.includes(UserRole.ROLE_EMPLOYEE) || user.roles.includes(UserRole.ROLE_ADMIN);
    }

    static isAuthenticated(): boolean {
        const user = AuthenticateJWT.getUser();
        if (!user) {
            return false;
        }

        const now = new Date().getTime() / 1000;
        if (user.exp < now) {
            AuthenticateJWT.removeToken();
            return false;
        }

        return true;
    }

}

export default AuthenticateJWT;